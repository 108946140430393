// src/styles/variables.scss
$danger-color: #d32f2f;
$secondary-color: #f6f6f6;
$primary-color-light: #ebeb56;
$primary-border-color: #5c73db;
$dark-grey-text: #4a4848;
$dropdown-border-color: #ced4da;

$table-header-color: #505470;
$table-content-color: #192a3e;
$table-headerbg-color: #f7f8fa;
$table-border-color: #eff0f4;
$selected-filter-color: #a6d5fa80;

// subscriptions colors
$active-plan-border: #3b43fd;
$active-plan-border-shadow: rgba(91, 56, 187, 0.12);
$card-bg-color: #f6f6f6;
$header-p-tag-color: #52575d;
$card-p-tag-color: #9f9f9f;
$check-icon-bg-color: #4763e41a;
$check-icon2-bg-color: #ebeff033;
$card-text-color: #b9bec1;
$add-ons-bottom-border: #e2e6eb;
$billing-cycle-card-background: #e2e6eb;
$inactive-subscription-btn-background: #3b43fd;
$billing-text-color: #636466;

$light-gray: #f3f3f3;
$medium-gray: #a7a9aa;
$success-color: #16a34a;
$text-color: #141415;
$success-bg-color: #0f90080f;
$danger-bg-color: #ff45450f;

$primary-color: #3b43fd;
$primary-light-color: #ececf7;
$dark-color: #333333;
$gray-color: #1f2937;
$light-color: #f6f6f6;

$tool-tip-bg: rgba(59, 67, 253, 0.06);
