@use 'variables';

/* Import Tailwind CSS */
@use 'tailwindcss/base';
@use 'tailwindcss/components';
@use 'tailwindcss/utilities';
@use 'shepherd.js/dist/css/shepherd.css';

:root {
  --primary-color: #{variables.$primary-color};
  --primary-light-color: #{variables.$primary-light-color};
  --light-color: #{variables.$light-color};
}

html,
body {
  height: 100%;
}
body {
  overflow: hidden !important;
  margin: 0;
  font-family: Inter, sans-serif !important;
}

input {
  font-size: 1rem;
  padding: 0.5rem 0.5rem !important;
  border: 1px solid #ced4da;
  border-radius: 8px;
  height: 48px;
}

textarea {
  font-size: 1rem;
  padding: 0.5rem 0.5rem !important;
  border: 1px solid #ced4da;
  border-radius: 8px;
}

textarea,
input:focus {
  outline: none !important;
  box-shadow: none !important;
}

p-inputIcon {
  cursor: pointer;
  span {
    margin-right: 10px;
  }
}

.shepherd-button {
  background: variables.$primary-color;
}

.text-primary {
  color: variables.$primary-color !important;
}
.text-danger {
  color: variables.$danger-color !important;
}

.custom-text-color .p-button-label {
  color: white !important;
}

.custom-tooltip {
  position: fixed !important;
  top: 55% !important;
  left: 80% !important;
}
.custom-tooltip-2 {
  position: fixed !important;
  top: 30% !important;
  left: 80% !important;
}
.custom-tooltip-3 {
  position: fixed !important;
  top: 55% !important;
  left: 80% !important;
}
.custom-tooltip-4 {
  position: fixed !important;
  top: 50% !important;
  left: 80% !important;
}
p-button.text-button {
  button {
    background: white !important;
    color: variables.$primary-color;
    font-weight: bold;
  }
}

.p-column-filter-buttonbar {
  display: none;
}

.input-danger {
  border-color: #f44336 !important;
}

.input-error {
  color: red;
}

.primary {
  background: variables.$primary-color !important;
  color: white !important;
}

.otp-box {
  border-radius: 8px !important;
  border: 1px solid variables.$primary-border-color !important;
  margin-right: 20px !important;
}

.toast-container .ngx-toastr {
  min-width: 300px;
  max-width: 500px;
  width: fit-content;
}

.d-flex {
  display: flex !important;
  align-items: center;
}

.terms-conditions-checkbox {
  margin-top: 25px;
  margin-bottom: 25px;
}

p-progressspinner {
  vertical-align: middle;
}

.p-progress-spinner {
  width: 24px;
  height: 24px;
  margin-top: 3px;
}

.p-progress-spinner-circle {
  stroke: white;
  stroke-width: 6px;
}

app-add-cte-form {
  p-progressspinner {
    vertical-align: middle;
  }

  .p-progress-spinner {
    width: 44px !important;
    height: 44px !important;
    margin-top: 3px !important;
  }

  .p-progress-spinner-circle {
    stroke: variables.$primary-color !important;
    stroke-width: 6px !important;
  }
}

app-sidebar {
  a {
    text-decoration: none !important;
  }
  .height-100 {
    height: calc(100vh - 69px) !important;
  }
}

app-header {
  grid-column: 1 / 3;
  z-index: 1000;
  position: relative;
  .p-toolbar {
    border-color: black !important;
    border-radius: 0;
    background: black;
    padding: 0.5rem 1.5rem 0.5rem 0.5rem;
    p {
      color: white;
      font-weight: bold;
    }
    i {
      color: white;
      margin: 0 10px;
      font-size: 1.2rem;
    }
    .avatar {
      border-radius: 50% !important;
      cursor: pointer;
    }
    .toggle-btn {
      cursor: pointer;
      margin-left: 5px;
    }
    .header-icons {
      i {
        cursor: pointer;
      }
    }
  }
}

app-dashboard-filters,
app-product-filters {
  .p-select {
    height: unset !important;
  }
  .p-multiselect {
    font-weight: bold;
    font-size: 14px;
    background: transparent;
  }
  .p-focus {
    outline: none !important;
    box-shadow: none !important;
  }
  .p-select,
  .p-multiselect {
    border: none !important;
    .p-select-panel {
      margin-top: 10px !important;
      border-radius: 15px;
    }
    span {
      font-size: 15px !important;
    }
  }
  calendaricon,
  chevrondownicon {
    padding-right: 10px !important;
    svg {
      width: 12px !important;
    }
  }
  .p-inputtext {
    font-size: 15px;
    border: none !important;
  }
}

.main-layout {
  border-top-left-radius: 40px;
}

.right-form {
  .p-sidebar {
    border-top-left-radius: 24px;
    width: 50% !important;
    form {
      min-height: 0;
    }
    .full-height-form {
      min-height: 0;
      padding-bottom: 100px;
    }
    app-add-role-form {
      form {
        min-height: auto !important;
      }
    }
  }
}

app-right-form-base {
  .w-50 {
    .p-sidebar {
      width: 50% !important;
    }
  }
  .w-25 {
    .p-sidebar {
      width: 25% !important;
    }
  }
  .w-75 {
    .p-sidebar {
      width: 75% !important;
    }
  }
  .w-80 {
    .p-sidebar {
      width: 80% !important;
    }
  }
  .w-90 {
    .p-sidebar {
      width: 90% !important;
    }
  }
  .w-100 {
    .p-sidebar {
      width: 100% !important;
    }
  }
}

.p-skeleton {
  height: 3rem !important;
}

app-add-role-form {
  .p-tabview-panels {
    padding: 0px;
    overflow-y: scroll;
    height: 65vh !important;
    padding-right: 25px;
  }
}

app-product-design-template {
  .p-orderlist-item {
    margin-bottom: 10px !important;
    border-radius: 5px !important;
  }
}

.p-highlight {
  .p-tabview-nav-link {
    color: variables.$primary-color !important;
    border-bottom-color: variables.$primary-color !important;
  }
}

.p-inputswitch-checked {
  .p-inputswitch-slider {
    background: variables.$primary-color !important;
  }
}

.p-select {
  width: 100% !important;
  border: 1px solid variables.$dropdown-border-color !important;
  height: 48px;
  align-items: center;
  border-radius: 8px;
  :focus {
    box-shadow: none;
    outline: none;
  }
}

app-table-action-button {
  p-button {
    button {
      width: auto !important;
      padding: 10px !important;
      display: flex !important;
      margin: 0 2px !important;
      background: none !important;
    }
  }
}

.p-button-primary {
  color: white !important;
  background: variables.$primary-color !important;
  border: none;
}

.p-button-secondary {
  color: black !important;
  background: variables.$secondary-color !important;
  border: none;
}

.p-datatable-header {
  background: transparent;
  border: none;
  padding: 12px 0;
  display: flex;
  justify-content: space-between;
}

.p-paginator {
  color: variables.$table-header-color;
  background-color: variables.$table-headerbg-color;
  border-radius: 8px;
  justify-content: flex-end;
}

.p-orderlist-controls {
  display: none;
}

p-inputGroup {
  p-inputGroupAddon {
    border-radius: 8px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    width: 100px !important;
  }
  input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.p-card-content {
  padding: 0px !important;
}

.p-accordion .p-accordion-content {
  padding: 0 !important;
}

.p-accordion-header-text {
  font-size: 16px;
  font-weight: 500;
  line-height: 18.8px;
  color: #192a3e;
}

.p-accordion-header-link {
  padding: 30px;
  height: 48px;
  border-radius: 8px;
  background-color: variables.$table-headerbg-color;
}

.p-orderlist .p-orderlist-list {
  padding: 0 !important;
}

.p-orderlist .p-orderlist-list-container {
  border: none !important;
}

.p-accordion-content {
  border: none !important;
}

.p-accordion-toggle-icon {
  margin-left: 20px;
  margin-right: 20px;
}

.p-orderlist-list-container ul li {
  padding: 0 !important;
}

.child-list {
  .p-orderlist-list-container ul li {
    margin-left: 20px;
  }
}

.p-orderlist-list {
  min-height: fit-content;
}

.p-orderlist .p-orderlist-list .p-orderlist-item {
  background-color: variables.$table-headerbg-color;
  border-radius: 8px;
  margin-top: 10px;
}

.p-orderlist .p-orderlist-list .p-orderlist-item:first-child {
  margin-top: 0px !important;
}

.p-accordion-toggle-icon {
  position: absolute;
  right: 0;
}

.label-button {
  button {
    width: auto !important;
    height: auto !important;
    display: inherit !important;
    background: transparent !important;
    padding: 0 !important;
    color: variables.$primary-color;
  }
}

app-add-kdeform {
  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    color: #8e9aa0;
    padding: 1rem 0;
    font-weight: 400;
    font-size: 16px;
    margin-right: 36px;
  }
  .p-tabview .p-tabview-nav .p-highlight .p-tabview-nav-link {
    color: #4763e4;
    font-weight: 600;
  }
  .p-tabview .p-tabview-panels {
    padding: 0;
  }
}

app-product-qr-settings-form {
  .p-accordion-tab {
    margin-bottom: 10px !important;
    .p-accordion-content {
      padding: 25px !important;
      margin-top: 10px !important;
      border-radius: 10px;
    }
    .p-accordion-header {
      .p-accordion-header-link {
        background-color: white !important;
        padding: 30px;
      }
    }
  }
}

.sidebar-orderlist {
  .p-orderlist .p-orderlist-list .p-orderlist-item {
    margin-top: 0px !important;
    border-radius: 0;
  }
}

.list {
  margin-top: 10px;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 10px;
}

.p-sidebar .p-sidebar-content {
  padding-bottom: 0;
  overflow: auto;
}

.selected-filter {
  .p-column-filter-menu-button {
    background: variables.$selected-filter-color !important;
    color: variables.$primary-color;
  }
}

.p-datatable .p-sortable-column .p-icon-wrapper {
  margin-top: 4px !important;
}

.upload-container {
  width: 150px;
  height: 150px;
  border: 2px dashed #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  border-radius: 8px;
  transition: border-color 0.3s ease;
}

.upload-container:hover {
  border-color: variables.$primary-color;
}

.file-input {
  display: none;
}

.upload-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
}

.upload-icon::before {
  content: '\f093'; /* Font Awesome Unicode for Upload Icon */
  font-family: 'Font Awesome 5 Free', sans-serif;
  font-weight: 900;
  font-size: 30px;
  color: variables.$primary-color;
  margin-bottom: 8px;
}

.upload-label p {
  color: variables.$primary-color;
  font-weight: bold;
  margin: 0;
}

.p-select-filter::placeholder {
  opacity: 0.8 !important;
}

.p-badge-dark {
  background-color: black !important;
}

.p-stepper-title {
  white-space: break-spaces;
}

p-stepperheader {
  button {
    font-size: 16px;
    text-align: left;
    align-items: center;
  }
}

.active-stepper {
  padding: 10px;
  background: variables.$primary-color;
  color: white;
  border-radius: 20px;
}

.pending-stepper {
  padding: 10px;
  background: variables.$table-border-color;
  color: lightgray;
  border-radius: 20px;
}

.cancel-button {
  button {
    color: black !important;
    background-color: white !important;
  }
}

app-action-button {
  p-button {
    margin-top: 1rem;
  }
}

app-right-form-footer {
  app-action-button {
    p-button {
      margin-top: 0 !important;
    }
  }
}

.shepherd-arrow:before {
  border-top: 1px solid;
  border-left: 1px solid;
}

.disabled-section {
  opacity: 0.5 !important;
  cursor: not-allowed !important;
  pointer-events: none !important;
  user-select: none !important;
}
form p {
  margin-top: 4px !important;
}
.gm-ui-hover-effect {
  display: none !important;
}

.p-tabview-nav {
  background: none !important;
}
.p-tabview-panels {
  background: none !important;
}
.p-tabview-nav-link {
  background: none !important;
}

starfillicon {
  svg {
    color: #ffbf00 !important;
  }
}

p-datePicker {
  span {
    width: 100%;
  }
}
.p-timeline-event-opposite {
  display: none !important;
}

.ngx-colors-overlay {
  z-index: 9999999 !important;
  ngx-colors-panel {
    z-index: 9999999 !important;
    div {
      z-index: 9999999 !important;
    }
  }
}

app-product-design-template {
  .p-highlight .p-tabview-nav-link {
    border-bottom-color: #000000 !important;
  }
  .p-tabview-nav {
    li {
      width: 100% !important;
      a {
        justify-content: center;
      }
    }
  }
}

.p-carousel-prev {
  position: absolute !important;
  z-index: 999;
}
.p-carousel-next {
  position: absolute !important;
  z-index: 999;
  right: 0 !important;
}
.p-carousel-container {
  position: relative !important;
}

p-dialog {
  ng-otp-input {
    div {
      .n-o-c {
        justify-content: center;
      }
    }
  }
}
